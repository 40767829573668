<!--  -->
<template>
   
  <div style="height: 100%">
    <el-card class="box-card">
      <div class="search">
        <span>筛选条件</span>
        <el-date-picker
          size="mini"
          style="margin-right: 15px"
          v-model="Timevalue"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <span>选择班级</span>
        <!-- <span>组件值：{{ Timevalue }}</span> -->
        <el-select v-model="classvalue" placeholder="请选择班级" size="mini">
          <el-option
            v-for="item in classOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="join-button">
          <el-button size="mini" type="primary" @click="headOperation(0)"
            >查询</el-button
          >
          <el-button size="mini" @click="headOperation(1)">重置</el-button>
        </div>
      </div>

      <el-scrollbar style="height: 80%">
        <template v-for="(it, ind) in tableData">
          <div class="box" :key="ind">
            <div class="icon">
              <img src="../../../assets/img/xiaoxi.png" alt="" />
            </div>
            <div class="con">
              <p>{{ it.title }}</p>
              <span>{{ it.content }}</span>
              <span class="time">
                {{ currentTime.formatDate(it.createTime * 1000) }}
                <!-- <i class="el-icon-delete" @click="deletes(it.id)"
                  ><i style="padding-left: 5px">删除</i></i
                ></span
              > -->
                <i class="el-icon-chat-dot-round" 
                  ><i style="padding-left: 5px">已读</i></i
                ></span
              >
            </div>
          </div>
          <el-divider></el-divider>
        </template>
        <el-empty
          description="暂无数据"
          v-if="tableData.length == 0"
        ></el-empty>
      </el-scrollbar>
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="page.pageSize"
        :current-page.sync="page.pageNum"
        layout="total, prev, pager, next"
        :total="page.total"
        background
        style="margin-top: 20px; text-align: center"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { createLogger } from "vuex";
import {
  classList,
  progressReminderGetProgressReminderPage,
  progressReminderRemove,
  progressReminderMarkAsRead,
} from "../../../api/api";
export default {
  data() {
    return {
      Timevalue: "",
      classOptions: [{ value: "", label: "全部" }],
      classvalue: "",
      page: {
        pageSize: 10,
        total: 100,
        pageNum: 1,
      },
      tableData: [],
    };
  },

  components: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      classList({}).then((res) => {
        if (res.message == "请求成功") {
          res.data.list.map((item) => {
            this.classOptions.push({
              value: item.id + "",
              label: item.className,
            });
          });
          this.classvalue = this.$route.query.classId || "";
          this.list();
        }
      });
    },
    list() {
      var obj = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        query: {
          classId: this.classvalue,
          startTime: this.currentTime.getTimestamp(this.Timevalue[0]),
          endTime: this.currentTime.getTimestamp(this.Timevalue[1]),
        },
      };
      progressReminderGetProgressReminderPage(obj).then((res) => {
        this.page.total = res.data.total;
        this.tableData = res.data.list;
        var idList = [];
        this.tableData.map((i) => {
          idList.push(i.id);
        });
        progressReminderMarkAsRead({ idList: idList }).then((res) => {
          if (res.message == "请求成功") {
          }
        });
      });
    },
    headOperation(val) {
      //0查询  1重置
      if (val == 1) {
        this.classvalue = "";
        this.Timevalue = "";
      }
      this.page.pageNum = 1;
      this.list();
    },
    //删除
    deletes(val) {
      progressReminderRemove({ id: val }).then((res) => {
        if (res.code == 0) {
          //站内信发消息
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.list();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.list();
    },
  },
};
</script>
<style lang='less' scoped>
@import "./index.less";
</style>
